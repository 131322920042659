import React, { Component } from 'react';
import { Theme } from '../../theme/theme';
import { StatisticsBar } from '../../common/components/statistics-bar.component';
import { FiltersBar } from '../../common/components/filters-bar.component';
import { TextField, FloatingActionButton } from 'material-ui';
import { DataTable } from '../../common/components/data-table.component';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { StudentDetailsModal } from './student-details-modal.component';
import { StudentSocialNetworksModal } from './student-social-networks-modal.container';
import { StudentProfilePictureModal } from './student-profile-picture-modal.container';
import { StudentPhotosModal } from './student-photos-modal.container';
import { StudentVideosModal } from './student-videos-modal.container';
import { ActionMenu } from '../../common/components/action-menu.component';
import {
  StudentInfoFragment,
  CreateStudentVariables
} from '../../ApolloComponents';
import { CreateEditStudentForm } from './create-edit-student-form';

const styles = {
  addButton: {
    float: 'right' as 'right'
  },
  actionIcon: {
    cursor: 'pointer',
    fontSize: 14,
    marginRight: 10
  },
  socialIcon: {
    fontSize: 16,
    marginRight: 3,
    marginLeft: 3
  },
  table: {
    marginLeft: '3%',
    marginRight: '3%'
  },
  iconOff: {
    color: Theme.DESCRIPTION_TEXT_COLOR
  },
  iconOn: {
    color: Theme.ACTIVE2_COLOR
  },
  iconEnrollment: {
    color: Theme.SECONDARY_COLOR
  }
};

interface Props {
  updateFilter: any;
  search: string;
  skip: number;
  limit: number;
  filterType: string;
  students: {
    statisticsData: any;
    error: any;
    loading: boolean;
    allStudents: {
      total: number;
      totalForFilter: number;
      items: StudentInfoFragment[];
    };
  };
  deleteStudent: (studentId: string) => void;
}

interface State {
  studentModal: StudentInfoFragment | null;
  socialModal: StudentInfoFragment | null;
  profilePictureModal: StudentInfoFragment | null;
  photosModal: StudentInfoFragment | null;
  videosModal: StudentInfoFragment | null;
  createStudentModal: Partial<CreateStudentVariables['student']> | null;
}

export class StudentsView extends Component<Props, State> {
  state: State = {
    createStudentModal: null,
    photosModal: null,
    videosModal: null,
    profilePictureModal: null,
    socialModal: null,
    studentModal: null
  };

  updatePagination(nextData: boolean) {
    if (nextData) {
      this.props.updateFilter({ skip: this.props.skip + this.props.limit });
    } else {
      let newSkip = this.props.skip - this.props.limit;

      if (newSkip < 0) {
        newSkip = 0;
      }

      this.props.updateFilter({ skip: newSkip });
    }
  }

  deleteStudent = (item: StudentInfoFragment) => {
    this.props.deleteStudent(item.id);
  };

  resetFilter = () => {
    this.props.updateFilter({ skip: 0, search: '' });
  };

  updateSearch(newValue: string) {
    this.props.updateFilter({ skip: 0, search: newValue });
  }

  shouldComponentUpdate(nextProps: Props) {
    return true;
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.students &&
      nextProps.students.allStudents &&
      nextProps.students.allStudents.items
    ) {
      const { studentModal, socialModal, profilePictureModal } = this.state;
      if (studentModal) {
        this.setState({
          studentModal:
            nextProps.students.allStudents.items.find(
              student => student.id === studentModal.id
            ) || null
        });
      }

      if (socialModal) {
        this.setState({
          socialModal:
            nextProps.students.allStudents.items.find(
              student => student.id === socialModal.id
            ) || null
        });
      }

      if (profilePictureModal) {
        this.setState({
          profilePictureModal:
            nextProps.students.allStudents.items.find(
              student => student.id === profilePictureModal.id
            ) || null
        });
      }
    }
  }

  buildStatistics() {
    const data = this.props.students.statisticsData || {};

    return [
      {
        name: 'All Participants',
        value: data.users || 0,
        color: Theme.ACTIVE1_COLOR,
        highlight: this.props.filterType === null,
        onClick: () =>
          this.props.updateFilter({ skip: 0, search: '', filterType: null })
      },
      {
        name: 'Custom Profile Picture',
        value: data.withNonDefaultProfilePicture || 0,
        color: Theme.ACTIVE2_COLOR,
        highlight: this.props.filterType === 'CUSTOM_PROFILE_PICTURE',
        onClick: () =>
          this.props.updateFilter({
            skip: 0,
            search: '',
            filterType: 'CUSTOM_PROFILE_PICTURE'
          })
      }
    ];
  }

  renderSocialBar = (item?: StudentInfoFragment) => {
    if (!item) {
      return null;
    }

    const socialIcons = [];

    if (item.facebook) {
      socialIcons.push(
        <FontAwesome
          key='facebook'
          style={{ ...styles.socialIcon, ...styles.iconOn }}
          tooltip={item.facebook}
          name='facebook'
        />
      );
    } else {
      socialIcons.push(
        <FontAwesome
          key='facebook'
          style={{ ...styles.socialIcon, ...styles.iconOff }}
          name='facebook'
        />
      );
    }

    if (item.linkedin) {
      socialIcons.push(
        <FontAwesome
          key='linkedin'
          style={{ ...styles.socialIcon, ...styles.iconOn }}
          tooltip={item.linkedin}
          name='linkedin'
        />
      );
    } else {
      socialIcons.push(
        <FontAwesome
          key='linkedin'
          style={{ ...styles.socialIcon, ...styles.iconOff }}
          name='linkedin'
        />
      );
    }

    if (item.snapchat) {
      if (item.snapchat === 'DURING_ENROLLMENT') {
        socialIcons.push(
          <FontAwesome
            key='snap'
            style={{ ...styles.socialIcon, ...styles.iconEnrollment }}
            tooltip='During Approval'
            name='snapchat'
          />
        );
      } else {
        socialIcons.push(
          <FontAwesome
            key='snap'
            style={{ ...styles.socialIcon, ...styles.iconOn }}
            tooltip={item.snapchat}
            name='snapchat'
          />
        );
      }
    } else {
      socialIcons.push(
        <FontAwesome
          key='snap'
          style={{ ...styles.socialIcon, ...styles.iconOff }}
          name='snapchat'
        />
      );
    }

    if (item.instagram) {
      socialIcons.push(
        <FontAwesome
          key='instagram'
          style={{ ...styles.socialIcon, ...styles.iconOn }}
          tooltip={item.instagram}
          name='instagram'
        />
      );
    } else {
      socialIcons.push(
        <FontAwesome
          key='instagram'
          style={{ ...styles.socialIcon, ...styles.iconOff }}
          name='instagram'
        />
      );
    }

    if (item.twitter) {
      socialIcons.push(
        <FontAwesome
          key='twitter'
          style={{ ...styles.socialIcon, ...styles.iconOn }}
          tooltip={item.twitter}
          name='twitter'
        />
      );
    } else {
      socialIcons.push(
        <FontAwesome
          key='twitter'
          style={{ ...styles.socialIcon, ...styles.iconOff }}
          name='twitter'
        />
      );
    }

    return <div>{socialIcons}</div>;
  };

  openUserModal = (user: StudentInfoFragment | null) => {
    this.setState({
      studentModal: user
    });
  };

  openSocialNetworksModal = (user: StudentInfoFragment | null) => {
    this.setState({
      socialModal: user
    });
  };

  openProfilePictureModal = (user: StudentInfoFragment | null) => {
    this.setState({
      profilePictureModal: user
    });
  };

  openPhotosModal = (user: StudentInfoFragment | null) => {
    this.setState({
      photosModal: user
    });
  };

  openVideoModal = (user: StudentInfoFragment | null) => {
    this.setState({
      videosModal: user
    });
  };

  openCreateStudentModal = (
    createStudentModal: Partial<CreateStudentVariables['student']> | null
  ) => {
    this.setState({
      createStudentModal
    });
  };

  renderModals() {
    return (
      <div>
        <StudentDetailsModal
          onRequestClose={() => this.openUserModal(null)}
          isOpen={this.state.studentModal !== null}
          student={this.state.studentModal}
        />
        <StudentSocialNetworksModal
          onRequestClose={() => this.openSocialNetworksModal(null)}
          isOpen={this.state.socialModal !== null}
          student={this.state.socialModal}
        />
        <StudentProfilePictureModal
          onRequestClose={() => this.openProfilePictureModal(null)}
          isOpen={this.state.profilePictureModal !== null}
          student={this.state.profilePictureModal}
        />
        <StudentPhotosModal
          onRequestClose={() => this.openPhotosModal(null)}
          isOpen={this.state.photosModal !== null}
          student={this.state.photosModal}
        />
        <StudentVideosModal
          onRequestClose={() => this.openVideoModal(null)}
          isOpen={this.state.videosModal !== null}
          student={this.state.videosModal}
        />
      </div>
    );
  }

  render() {
    const tableDesc = [
      {
        headerName: '',
        width: 5,
        render: (item: StudentInfoFragment) => (
          <ProfileAvatar size={30} src={item.profilePictureUrl} />
        )
      },
      { headerName: 'Name', width: 15, valueFrom: 'name' },
      { headerName: 'Email', width: 20, valueFrom: 'msjEmail' },
      {
        headerName: 'Social',
        rowStyle: { overflow: 'visible' },
        width: 10,
        render: (item: StudentInfoFragment) => this.renderSocialBar(item)
      },
      {
        headerName: 'Groups',
        width: 10,
        render: (item: StudentInfoFragment) =>
          item.courses ? item.courses.length : 0
      },
      {
        headerName: 'Actions',
        width: 15,
        rowStyle: {
          overflow: 'visible'
        },
        render: (item: StudentInfoFragment) => (
          <div>
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.openUserModal(item)}
              tooltip='View User'
              name='eye'
            />
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.openSocialNetworksModal(item)}
              tooltip='Social Networks'
              name='share-alt'
            />
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.openProfilePictureModal(item)}
              tooltip='Profile Picture'
              name='camera'
            />
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.openPhotosModal(item)}
              tooltip='Gallery'
              name='image'
            />
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.openVideoModal(item)}
              tooltip='Videos View'
              name='video'
            />

            <FontAwesome
              style={styles.actionIcon}
              onClick={() =>
                this.openCreateStudentModal({
                  email: item.msjEmail || '',
                  firstName: item.firstName || '',
                  lastName: item.lastName || '',
                  username: item.username || '',
                  existingId: item.id,
                  phoneNumber: item.phoneNumber || ''
                })
              }
              tooltip={'Edit'}
              name={'pencil-square-o'}
            />
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.deleteStudent(item)}
              tooltip='Remove'
              name='trash'
            />
          </div>
        )
      }
    ];

    const totalForFilter = this.props.students.allStudents
      ? this.props.students.allStudents.totalForFilter
      : 0;
    const items = this.props.students.allStudents
      ? this.props.students.allStudents.items
      : [];
    const stats = this.buildStatistics();

    const { createStudentModal } = this.state;

    return (
      <div>
        {this.renderModals()}
        <ActionMenu
          onRequestClose={() => this.setState({ createStudentModal: null })}
          title={
            createStudentModal && createStudentModal.existingId
              ? 'Update Participant'
              : 'Create Participant'
          }
          open={!!createStudentModal}
        >
          <CreateEditStudentForm initialStudent={createStudentModal} />
        </ActionMenu>
        <StatisticsBar data={stats} />
        <FiltersBar title='Participants'>
          <TextField
            hintText='Search Participants...'
            value={this.props.search}
            onChange={(event, newValue) => this.updateSearch(newValue)}
          />
          <FloatingActionButton
            style={styles.addButton}
            onClick={() => this.openCreateStudentModal({})}
          >
            <FontAwesome name='plus' />
          </FloatingActionButton>
        </FiltersBar>
        <DataTable
          loading={this.props.students.loading}
          skip={this.props.skip}
          limit={this.props.limit}
          total={totalForFilter}
          onNext={() => this.updatePagination(true)}
          onPrev={() => this.updatePagination(false)}
          onResetFilter={this.resetFilter}
          style={styles.table}
          idField='id'
          data={items}
          description={tableDesc}
        />
      </div>
    );
  }
}
